import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA} from '@angular/material';
import { RutaService } from 'src/app/services/operaciones/ruta.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagenComponent } from '../imagen/imagen.component';
import { ExcelService } from 'src/app/services/excel.service';
import { utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {
  public displayedColumns = ['numero', 'fecha','hora', 'tipo', 'descripcion', 'ubicacion', 'fotografia'];
  dataSource: Array<any> = [];
  eventos: Array<any> = [];
  usuarioSession: any;
  isZAM: any;
  isZAMVal: boolean=false;
  idViajeZAM: any;
  public _utils:utils;

  constructor(
    public dialogRef: MatDialogRef<EventosComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _rs: RutaService,
    public sanitizer: DomSanitizer,
    private excelService: ExcelService,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this._utils = new utils();
    this.usuarioSession = JSON.parse(sessionStorage.getItem("usuario")); 
    this.isZAM = this.usuarioSession.activoZAM;
    if(this.isZAM != 0){
      this.isZAMVal = true
    }else{
      this.isZAMVal = false
    }
    this.consultarEventos();
    this.consultarRutaViajeZAM();
  }

  consultarEventos() {
    this._rs.consultarRutaEventos(this.data.idRuta).subscribe(
      result => {
        this.eventos = result;
        this.dataSource = this.eventos;
        console.log(result);
      },
      error => {
        console.log(error);
      }
    );
  }
  consultarRutaViajeZAM() {
    this._rs.consultarRutaViajeZAM(this.data.idRuta).subscribe(
      result => {
        this.idViajeZAM = result[0].idRutaExterna;
      },
      error => {
        console.log(error);
      }
    );
  }

  exportar():void {
    if(this.eventos.length > 0){
      //Recorrer y quitar la propiedad pathFot (no incluir eso al excel).
      for(var x=0; x<this.eventos.length; x++){
        delete this.eventos[x].pathFoto;
        delete this.eventos[x].nombreFoto;
        delete this.eventos[x].extensionFoto;
        delete this.eventos[x].idEventoTipo;
        delete this.eventos[x].idRazonSocial;
        delete this.eventos[x].idRuta;
        delete this.eventos[x].idRutaEvento;
        delete this.eventos[x].b64;

      }
      this.excelService.exportAsExcelFile(this.eventos, 'evidencias');
    }else{
      //console.log("No hay evidencias para mostrar.")
      this._utils.toast("No hay eventos para exportar!",this.snackBar);
    }
  }

  cerrarModal() {
    this.dialogRef.close();
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  modalImagen(e: any) {
    const diagRef = this.dialog.open(ImagenComponent, {
      disableClose: false,
      width: '75%',
      height: '90%',
      data: e
    });
  }

}
