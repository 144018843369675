import { Injectable, EventEmitter } from '@angular/Core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from "rxjs/Observable";
import { map } from 'rxjs/operators';

import { utils } from '../../../../../utils/utils';
import * as $ from 'jquery';
import { TransportistaModelo } from '../../../../../models/configuracion/catalogos/operaciones/transportista/transportista.modelo'
import { DispositivoModelo } from '../../../../../models/configuracion/catalogos/operaciones/dispositivo/dispositivos.modelo';
import { JornadaModelo } from '../../../../../models/configuracion/catalogos/operaciones/jornada/jornadas.modelo';
import { VehiculoModelo } from 'src/app/models/configuracion/catalogos/operaciones/vehiculos.modelo';

@Injectable()
export class TransportistaService{
   // public usuario:Array<any>;
   public headers = new Headers({'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;'});
    //public headers = new Headers({'Content-Type': 'application/json; charset=utf-8'});
   

    constructor(private _http: Http,  private _utils: utils) {
        this._utils = new utils();
    }
   
    consultarTransportista(idTransportista: number,idCompania:number): Observable<TransportistaModelo> {
     var transportista = new TransportistaModelo(0,"","","","","","",0,0,"");
     transportista.idTransportista = idTransportista;
     transportista.idRazonSocial = idCompania;
     
     const json = JSON.stringify(transportista);
        const params = json;
      return this._http
        .post(`${this._utils.url}Transportista/consultarTransportista/`,params, {headers: this.headers} )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              console.log(response.text());
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
    }

    consultarVehiculosTipoTransportista(idRazonSocial: number): Observable<any> {
      // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
  
     let params = {
       idRazonSocial : idRazonSocial
     }
    
     const json = JSON.stringify(params);
      
      return this._http
        .post(`${this._utils.url}Transportista/consultarVehiculoTipoTransportista/`,json, {headers: this.headers} )
        .map((response) => {
          try {
            if (response.json() !== 'null') {
              //console.log(response.text());
              return JSON.parse(response.text());
            } else {
              return null;
            }
          } catch (e) {
            return null;
          }
        });
    }

    consultarTransportistas(transportistaModelo : TransportistaModelo) {
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
        var transportista = new TransportistaModelo(0,"","","","","","",0,0,"");
        transportista.idRazonSocial = transportistaModelo.idRazonSocial;
        const json = JSON.stringify(transportista);
        const params = json;
        return this._http
          .post(`${this._utils.url}Transportista/consultarTransportistas/` ,params, {headers: this.headers})
          .map((response) => {
            try {
              if (response.json() !== 'null') {
                console.log(response.text());
              return JSON.parse(response.text());
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
      }

      consultarTransportistasTipoVehiculo(params) {
        const json = JSON.stringify(params);

        return this._http
          .post(`${this._utils.url}Transportista/consultarTransportistasTipoVehiculo/` , json, {headers: this.headers})
          .map((response) => {
            try {
              if (response.json() !== 'null') {
                console.log(response.text());
              return JSON.parse(response.text());
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
      }

      consultarTransportistasGrid(transportistaModelo : TransportistaModelo) {
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
        var transportista = new TransportistaModelo(0,"","","","","","",0,0,"");
        transportista.idRazonSocial = transportistaModelo.idRazonSocial;
        const json = JSON.stringify(transportista);
        const params = json;
        return this._http
          .post(`${this._utils.url}Transportista/consultarTransportistasGrid/` ,params, {headers: this.headers})
          .map((response) => {
            try {
              if (response.json() !== 'null') {
                console.log(response.text());
              return JSON.parse(response.text());
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
      }


      consultarRutasTransportistas(fechaInicio: string, fechaFin: string,idRazonSocial: any) {
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
        return this._http
          .get(`${this._utils.url}Transportista/consultarRutasTransportistas?fechaInicio=`+fechaInicio+`&fechaFin=`+fechaFin+`&idRazonSocial=`+idRazonSocial )
          .map((response) => {
            try {
              if (response.json() !== 'null') {
                
                return response.json();
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
      }

      consultarTransportistaRuta(params) {
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
      
        const json = JSON.stringify(params);
        
        return this._http
          .post(`${this._utils.url}Transportista/consultarTransportistasRuta/` ,json, {headers: this.headers})
          .map((response) => {
            try {
              if (response.json() !== 'null') {
                console.log(response.text());
              return JSON.parse(response.text());
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
      }



      asignarRutaTransportista(params) {
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
      
        const json = JSON.stringify(params);
        
        return this._http
          .post(`${this._utils.url}Transportista/asignarRutaTransportista/` ,json, {headers: this.headers})
          .map((response) => {
            try {
              if (response.json() !== 'null') {
                console.log(response.text());
              return JSON.parse(response.text());
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
      }

      enviarRutaOperaciones(params) {
        const json = JSON.stringify(params);
        
        return this._http
          .post(`${this._utils.url}Transportista/enviarRutaOperaciones/` ,json, {headers: this.headers})
          .map((response) => {
            try {
              if (response.json() !== 'null') {
                console.log(response.text());
              return JSON.parse(response.text());
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
      }

      consultarDatosRuta(params) {
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
      
        const json = JSON.stringify(params);
        
        return this._http
          .post(`${this._utils.url}Transportista/consultarDatosRuta/` ,json, {headers: this.headers})
          .map((response) => {
            try {
              if (response.json() !== 'null') {
                console.log(response.text());
              return JSON.parse(response.text());
              } else {
                return null;
              }
            } catch (e) {
              return null;
            }
          });
      }


   

   
    agregarTransportista(transportistaModelo: TransportistaModelo) {
        // let params = new URLSearchParams();
        // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
        const json = JSON.stringify(transportistaModelo);
        const params = json;
        return this._http
          .post(`${this._utils.url}Transportista/agregarTransportista/`, params, {headers: this.headers} )
          .map((response) => {
            try {
              if(response.statusText !== 'null' && response.statusText == 'OK'){
                console.log(response.text());
                return response.text();
              }else{
                return null;
              }
            } catch (error) {
              console.log(error);
              return null; 
            } 
          });
    }

    agregarRutaTransportista(transportistaModelo: any) {
      // let params = new URLSearchParams();
      // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
      const json = JSON.stringify(transportistaModelo);
      const params = json;
      return this._http
        .post(`${this._utils.url}Transportista/agregarRutaTransportista/`, params, {headers: this.headers} )
        .map((response) => {
          try {
            if(response.statusText !== 'null' && response.statusText == 'OK'){
              console.log(response.text());
              return response.text();
            }else{
              return null;
            }
          } catch (error) {
            console.log(error);
            return null; 
          } 
        });
  }

    agregarVehiculoTipoTransportista(transportistaModelo: any) {
      // let params = new URLSearchParams();
      // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
      const json = JSON.stringify(transportistaModelo);
      const params = json;
      return this._http
        .post(`${this._utils.url}Transportista/agregarVehiculoTipoTransportista/`, params, {headers: this.headers} )
        .map((response) => {
          try {
            if(response.statusText !== 'null' && response.statusText == 'OK'){
              console.log(response.text());
              return response.text();
            }else{
              return null;
            }
          } catch (error) {
            console.log(error);
            return null; 
          } 
        });
  }

    actualizarTransportista(transportistaModelo: TransportistaModelo) {
      const json = JSON.stringify(transportistaModelo);
      const params = json;
      return this._http
      .post(`${this._utils.url}Transportista/ActualizarTransportista/`, params, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            console.log(response.text());
            return response.text();
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
    }

  eliminarTransportista(transportistaModelo: TransportistaModelo) {
    const json = JSON.stringify(transportistaModelo);
    const params = json;
    return this._http
    .post(`${this._utils.url}Transportista/eliminarTransportista/`, params, {headers: this.headers} )
    .map((response) => {
      try {
        if(response.statusText !== 'null' && response.statusText == 'OK'){
          console.log(response.text());
          return response.text();
        }else{
          return null;
        }
      } catch (error) {
        console.log(error);
        return null; 
      } 
    });
  }

  actualizarRutaDetalleTransportista(rutaDetalle) {
    const json = JSON.stringify(rutaDetalle);
    const params = json;
    return this._http
    .post(`${this._utils.url}Transportista/EditarRutaDetalleTransportista/`, params, {headers: this.headers} )
    .map((response) => {
      try {
        if(response.statusText !== 'null' && response.statusText == 'OK'){
          console.log(response.text());
          return response.text();
        }else{
          return null;
        }
      } catch (error) {
        console.log(error);
        return null; 
      } 
    });
  }

  guardarFiles(file, idRazonSocial, idRuta, tipoArchivo, extensionFile, idRutaTransportista, idUsuario): Observable<any> {
    const formData = new FormData();
    formData.append('archivo', file);
    formData.append('idRazonSocial', idRazonSocial);
    formData.append('idRuta', idRuta);
    formData.append('tipoArchivo', tipoArchivo);
    formData.append('extensionFile', extensionFile);
    formData.append('idRutaTransportista', idRutaTransportista);
    formData.append('idUsuario', idUsuario);

    return this._http.post(`${this._utils.url}Transportista/guardarArchivos`, formData).pipe(
        map((res: any) => {
            return JSON.parse(res.json());
        }));
  }

  guardarArchivosBolsaTransportista(file, idRazonSocial, idRuta, tipoArchivo, extensionFile, idRutaTransportista, idUsuario): Observable<any> {
    const formData = new FormData();
    formData.append('archivo', file);
    formData.append('idRazonSocial', idRazonSocial);
    formData.append('idRuta', idRuta);
    formData.append('tipoArchivo', tipoArchivo);
    formData.append('extensionFile', extensionFile);
    formData.append('idRutaTransportista', idRutaTransportista);
    formData.append('idUsuario', idUsuario);

    return this._http.post(`${this._utils.url}Transportista/guardarArchivosBolsaTransportista`, formData).pipe(
        map((res: any) => {
            return JSON.parse(res.json());
        }));
  }

  descargarArchivoBolsaTransportista(idTransportistaRutaArchivo, idRazonSocial): Observable<any> {
    const formData = new FormData();
    formData.append('idTransportistaRutaArchivo', idTransportistaRutaArchivo);
    formData.append('idRazonSocial', idRazonSocial);


    return this._http.post(`${this._utils.url}Publicaciones/descargarArchivoBolsaTransportista`, formData).pipe(
        map((res: any) => {
            return res.json();
        }));
}

  descargarFiles(idRazonSocial, idRuta, tipoArchivo, idRutaTransportista, idUsuario): Observable<any> {
    const formData = new FormData();
    formData.append('idRazonSocial', idRazonSocial);
    formData.append('idRuta', idRuta);
    formData.append('tipoArchivo', tipoArchivo);
    formData.append('idRutaTransportista', idRutaTransportista);
    formData.append('idUsuario', idUsuario);

    return this._http.post(`${this._utils.url}Transportista/descargarArchivos`, formData).pipe(
      map((res: any) => {
        return res.json();
      }));
  }

  consultarRutaTransportista(rutaT) {
    const json = JSON.stringify(rutaT);
    const params = json;

    return this._http
      .post(`${this._utils.url}Transportista/consultarRutaTransportista/` ,params, {headers: this.headers})
      .map((response) => {
        try {
          if (response.json() !== 'null') {
             return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  verificarFechaRespuesta(rutaT) {
    const json = JSON.stringify(rutaT);
    const params = json;
console.log(this._utils.url)
    return this._http
      .post(`${this._utils.url}Transportista/verificarFechaRespuesta/` ,params, {headers: this.headers})
      .map((response) => {
        try {
          if (response.json() !== 'null') {
             return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  declinarContraOferta(rutaT) {
    const json = JSON.stringify(rutaT);
    const params = json;

    return this._http
      .post(`${this._utils.url}Transportista/declinarContraOferta/` ,params, {headers: this.headers})
      .map((response) => {
        try {
          if (response.json() !== 'null') {
             return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }
  /////////////PERMISIONARIOS
  consultarPermisionarios(idRazonSocial: number): Observable<any> {
    const json = JSON.stringify({idRazonSocial: idRazonSocial});
    const params = json;

    return this._http
      .post(`${this._utils.url}Transportista/consultarPermisionarios/`, params, {headers: this.headers} )
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  agregarPermisionario(permisionario: any) {
    const json = JSON.stringify(permisionario);
    const params = json;
    return this._http
      .post(`${this._utils.url}Transportista/agregarPermisionario/`, params, {headers: this.headers} )
      .map((response) => {
        try {
          if(response.statusText !== 'null' && response.statusText == 'OK'){
            //console.log(response.text());
            return response.text();
          }else{
            return null;
          }
        } catch (error) {
          console.log(error);
          return null; 
        } 
      });
  }


  //CATALOGO PERMISIONARIO 
  agregarPermisionarioPreferente(idRazonSocial, clientes,idTransportista) {
    let param = {
        idRazonSocial: idRazonSocial,
        clientes: clientes,
        idTransportista:idTransportista
    }    
    const json = JSON.stringify(param);
    return this._http
      .post(`${this._utils.url}Transportista/agregarClientePermisionario/`, json, { headers: this.headers })
      .map((response) => {
        try {
            if (response.json() !== 'null') {                    
                return JSON.parse(response.text());
            } else {                    
                return null;
            }
        } catch (e) {
            return null;
        }
      });
    }

    consultaPermisionariosClientesPreferentes(idTransportista) {
    let param = {        
      idTransportista:idTransportista
    }    
    const json = JSON.stringify(param);
    return this._http
      .post(`${this._utils.url}Transportista/consultaPermisionariosClientesPreferentes/`, json, { headers: this.headers })
      .map((response) => {
          try {
              if (response.json() !== 'null') {                    
                  return JSON.parse(response.text());
              } else {                    
                  return null;
              }
          } catch (e) {
              return null;
          }
      });
    }

    enviarCorreoPermisionario(params) {    
      const json = JSON.stringify(params);

      return this._http
        .post(`${this._utils.url}Transportista/enviarCorreoPermisionario/`, json, { headers: this.headers })
        .map((response) => {
            try {
                if (response.json() !== 'null') {                    
                    return JSON.parse(response.text());
                } else {                    
                    return null;
                }
            } catch (e) {
                return null;
            }
        });
      }

      downloadApp(): Observable<any[]>{

        return this._http.post(`${this._utils.url}Cliente/downloadApp`, {headers: this.headers}).pipe(
          map((res: any) => {
              try {
                return res.json();
              }
              catch (error){
                  console.log(error);
                  return null;
              }  
          }));
      }

      validarConexionZamPlanner(idRazonSocial: any): Observable<any[]>{

        return this._http.post(`${this._utils.url}Cliente/validarConexionZamPlanner?idRazonSocial=`+idRazonSocial, {headers: this.headers}).pipe(
          map((res: any) => {
              try {
                return res.json();
              }
              catch (error){
                  console.log(error);
                  return null;
              }  
          }));
      }

      // downloadApp() {
      //   return this._http.post(
      //     this._utils.url + `Cliente/downloadApp`, { responseType: 'arrayBuffer'}
      //   ).subscribe(file => {
      //     this.DownloadFile(file, 'appRoutech.apk', 'application/vnd.android.package-archive');
      //   }, error => {
      //   });
      // }

      // private DownloadFile(file, fileName, fileType) {
      //   const newBlod = new Blob([file], {type: fileType});
      //   if(window.navigator  && window.navigator.msSaveOrOpenBlob) {
      //     window.navigator.msSaveOrOpenBlob(newBlod);
      //   }
      //   const data = window.URL.createObjectURL(newBlod);
      //   const link =  document.createElement('a');
      //   link.href = data;
      //   link.download = fileName;
      //   link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
      //   setTimeout(function() {
      //     window.URL.revokeObjectURL(data);
      //     link.remove();
      //   },100)
      // }
}



